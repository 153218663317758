import Headroom from "headroom.js";

const headerClass = "main-header";
const wSelect = document.querySelector(".wSelect-options-holder");
export const headroom = () => {
	// grab an element
	const header = document.querySelector(`[data-${headerClass}]`);
	// construct an instance of Headroom, passing the element
	const options = {
		classes: {
			// when element is initialised
			initial: `${headerClass}-init`,
			// when scrolling up
			pinned: `${headerClass}--pinned`,
			// when scrolling down
			unpinned: `${headerClass}--unpinned`,
			// when above offset
			top: `${headerClass}--top`,
			// when below offset
			notTop: `${headerClass}--not-top`,
			// when at bottom of scroll area
			bottom: `${headerClass}--bottom`,
			// when not at bottom of scroll area
			notBottom: `${headerClass}--not-bottom`,
			// when frozen method has been called
			frozen: `${headerClass}--frozen`,
			// multiple classes are also supported with a space-separated list
			pinned: `${headerClass}--pinned`,
		},
	};
	const headroom = new Headroom(header, options);
	// initialise
	headroom.init();

	// if overlay menu active
	headroom.onUnpin = function () {
		if (wSelect) {
			wSelect.removeAttribute("style");
		}
		if (header.classList.contains("text-white")) {
			this.pin();
		}
	};
};
